const BASE_URL = 'https://api-hml.sap.senai.br/';

export const environment = {
  name: 'HOMOLOGAÇÃO',
  production: false,
  ci: false,
  authenticationServiceUrl: 'http://localhost:3000/api',
  crudServiceUrl: 'https://localhost:3000/api',
  urlToOauthToken: BASE_URL + 'api/oauth/token',
  urlToApi: BASE_URL + 'api/',
  urlToApiPreviewFiles: BASE_URL,
  urlToAvatar: BASE_URL,
  urlToFiles: BASE_URL + 'storage/manual-attachments',
  urlToCoverSheet: BASE_URL + 'storage/cover-sheets',
  urlToEvaluatorManual: BASE_URL + 'storage/evaluator-manual',
  urlToSisbiaApi: 'https://api.sisbia.hml.senai.br/api/client/',
  sisbiaKey: 'user=SAP&token=$2y$10$jxP4sBv3dITqIKfRp0stMupQvLot1F2UZK4UTESE1EuwAgD6C87K.',
  SIAC: {
    // apiURL: 'https://api.siac.hml.senai.br/api/client/sap/events', // HML
    apiURL: 'https://api.siac.senai.br/api/client/sap/events',
  }
};
